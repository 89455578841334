<template>
    <div class="container">

        <div class="row">
            <div>
                <h3>Benutzer</h3><br/>

              <table class="table table-hover" v-if="!dataLoading">
                <thead class="thead-dark2">
                <tr>
                  <td colspan="3"></td>
                  <td colspan="4">Berechtigungen</td>
                </tr>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col"># Songs</th>
                  <th scope="col"># Votes</th>
                  <th scope="col">Voten</th>
                  <th scope="col">Hinzufügen</th>
                  <th scope="col">Autom. Freischalten</th>
                  <th scope="col">Admin</th>
                </tr>

                </thead>
                <tbody>
                  <tr v-for="client in clients" :key="client.client_id">
                    <td>
                      {{ client.nickname }}<br />
                      ({{ client.name }})
                    </td>
                    <td>{{ client.entries_count }}</td>
                    <td>{{ client.votes_count }}</td>
                    <td>
                      <select @change="setPermission(client.client_id, 'vote', $event.target.value)">
                        <option value="" :selected="!client.permissions.vote">Von Raum</option>
                        <option value="on" :selected="client.permissions.vote === 'on'">Ja</option>
                        <option value="off" :selected="client.permissions.vote === 'off'">Nein</option>
                      </select>
                    </td>
                    <td>
                      <select @change="setPermission(client.client_id, 'add', $event.target.value)">
                        <option value="" :selected="!client.permissions.add">Von Raum</option>
                        <option value="on" :selected="client.permissions.add === 'on'">Ja</option>
                        <option value="off" :selected="client.permissions.add === 'off'">Nein</option>
                      </select>
                    </td>
                    <td>
                      <select @change="setPermission(client.client_id, 'autoUnlock', $event.target.value)">
                        <option value="" :selected="!client.permissions.autoUnlock">Von Raum</option>
                        <option value="on" :selected="client.permissions.autoUnlock === 'on'">Ja</option>
                        <option value="off" :selected="client.permissions.autoUnlock === 'off'">Nein</option>
                      </select>
                    </td>
                    <td>
                      <input @click="setPermission(client.client_id, 'admin', $event.target.checked)" type="checkbox" :checked="client.permissions.admin">
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'Home',
        components: {},
        mounted: async function () {
            this.loadData()
        },
        watch: {},
        methods: {
          loadData: async function () {
              const self = this
              this.dataLoading = true;
              const response = await axios.get('/host/clients');

              this.clients = response.data;
              this.dataLoading = false;

              window.setTimeout(function() {
                self.loadData()
              }, 10 * 1000)
          },
          setPermission: async function(client_id, permissionType, value) {
            console.log(client_id, permissionType, value)
            try {
              const response = await axios.post('/host/user-settings', {
                client_id: client_id,
                permissionType: permissionType,
                value: value === '' ? null : value
              });

              this.clients = response.data;
            }
            catch (e) {
              console.log(e)
              alert('Es ist ein Fehler aufgetreten.')
            }
          }
        },
        computed: {},
        data: function () {
            return {
                clients: [],
                dataLoading: false
            }
        }
    }
</script>
<style lang="stylus" scoped>

    .table-hover {
        width: 100%;
    }

    .table-hover tfoot a {
        color: black;
    }

    .table-hover tbody tr:hover td {
        background-color: rgba(69, 153, 55, 0.2);
    }

    .thead-dark2 {
        background-color: rgba(69, 153, 55, 0.2);
    }

    .c-grid-block {
        background-color: rgba(126, 186, 118, 0.1);
        color: #000000;
        /* box-shadow: 0 0.2rem 0.5rem 0 rgba(0, 0, 0, 0.1), 0 0.3rem 0.1rem 0 rgba(0, 0, 0, 0.05);
        */
    }

</style>